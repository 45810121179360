<template>
  <el-dialog
    title="新增跟进记录"
    :visible="dialogVisible"
    :modal="false"
    width="30%"
    :before-close="handleClose"
  >
    <el-form
      ref="form"
      label-width="80px"
      :rules="rules"
      :model="formData"
    >
      <el-form-item
        label="跟进内容"
        prop="content"
      >
        <el-input
          type="textarea"
          v-model="formData.content"
          placeholder="请输入内容"
          :rows="3"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="handleFollowLog('form')"
        >确定</el-button>
        <el-button @click="handleClose">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { followLogAddAPI } from '../api'
export default {
  name: 'AddFollowLog',
  data () {
    return {
      formData: {
        content: '',
        uid: this.uid,
      },
      rules: {
        content: [
          { trigger: 'blur', message: '请输入内容', required: true }
        ],
      },
    }
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default () {
        return 0
      },
    },
    uid: {
      type: Number,
      require: true,
      default () {
        return 0
      }
    },
  },
  methods: {
    handleClose () {
      this.$emit('close-form')
    },
    handleFollowLog (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          followLogAddAPI(this.formData).then(() => {
            this.$message.success('操作成功！')
            this.$emit('modify-success')
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
</style>
